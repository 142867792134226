<template>
    <div>
        <div class="header">
            <span class="header_txt">修改密码</span>
        </div>
        <div class="content">
            <Form :model="formItem" :label-width="100" style="width: 500px;">
                <FormItem label="原始密码：">
                    <Input v-model="formItem.old_pwd" type="password" placeholder="请输入原始密码"></Input>
                </FormItem>
                <FormItem label="新密码：">
                    <Input v-model="formItem.new_pwd" type="password" placeholder="请输入新密码"></Input>
                </FormItem>
                <FormItem label="再次输入：">
                    <Input v-model="update_password" type="password" placeholder="请再次输入新密码"></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="addBtn">保存</Button>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
import {loginModify} from '@/api/index'
export default {
  name: "password",
  data() {
    return {
      update_password:'',
      formItem: {
        old_pwd:'',
        new_pwd:''
      },
    }
  },
  methods: {
    addBtn(){
      if (this.update_password == this.formItem.new_pwd) {
        loginModify(this.formItem).then(res=>{
          this.$Message.success(res.msg)
          this.formItem.old_pwd = ''
          this.formItem.new_pwd = ''
          this.update_password = ''
          this.$router.push({path: '/'})
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        this.$Message.error('两次密码输入不一致')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.content{
    margin: 20px 0;
}
</style>